// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  env_name: 'dev',
  firebase: {
    apiKey: 'AIzaSyDayXFlH3aJTwhrYN6cXB9jcjOPCDQNx2Y',
    authDomain: 'staging.mixidea.org',
    databaseURL: 'https://mixidea-temp-staging.firebaseio.com',
    projectId: 'mixidea-temp-staging',
    storageBucket: 'mixidea-temp-staging.appspot.com',
    messagingSenderId: '341097920106',
    appId: '1:341097920106:web:acd279aad3feeb59c73c13',
    measurementId: 'G-TMZZ7E2L2F',
    timestampsInSnapshots: true,
  },
  cloudfunction_base_url: 'https://us-central1-mixidea-temp-staging.cloudfunctions.net/',
  eventlist_before_term: 1 * 24 * 60 * 60 * 1000,
  google_client_secret_id: '46563705700-pjadn4rbpviho1411tvpeoe23nljpce1.apps.googleusercontent.com',
  record_socket_url: 'https://recordingtranscription2.mixidea.org/mixidea',
  record_https_url: 'https://recordingtranscription2.mixidea.org/record_recognition/',
  timeerror_correction_url_cloudfunction: 'https://us-central1-mixidea-temp-staging.cloudfunctions.net/dateRetrieve',
  timeerror_correction_url_ec2: 'https://recordingtranscription2.mixidea.org/date_retrieve',
  timeerror_correction_url_poxix: 'https://ntp-a1.nict.go.jp/cgi-bin/jst',
  // headlesschrome_wakeup_url: 'https://us-central1-mixidea-temp-staging.cloudfunctions.net/wakeupHeadlessChrome',
  // logserver_url: 'https://us-central1-mixidea-temp-staging.cloudfunctions.net/client_log',
  logserver_url: 'https://recordingtranscription2.mixidea.org/client_log',
  is_server_log: true,

  line_auth: 'https://us-central1-mixidea-temp-staging.cloudfunctions.net/lineLoginAuth2',
  ogpWrittendebateUrl: 'https://us-central1-mixidea-temp-staging.cloudfunctions.net/ogpWrittendebateUrl',
  term_of_service_url: 'https://mixidea.org/assets/html/term_of_service.html',
  privacy_policy_url: 'https://mixidea.org/assets/html/privacy_policy.html',

  // tabbycat_setting_url: 'http://localhost:3000',
  tabbycat_setting_url: 'https://us-central1-mixidea-temp-staging.cloudfunctions.net',

  facebook: {
    appId: '531572680566695',
    version: 'v3.3',
  },
  skyway: {
    apiKey: 'ae4c5882-2d66-472f-9d04-5859448eca41',
    // debug: 3
    debug: 1,
    apiKeys: {
      VideoGame: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      VideoGameFullmesh: 'aa900e9d-353a-41ee-b1b3-751a97104f4e',
      VideoAuditorium: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      VideoGameInAuditorium: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      VideoGameInAuditoriumLarge: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      VideoGameInAuditoriumFullmesh: 'aa900e9d-353a-41ee-b1b3-751a97104f4e',
      VideoPresen: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      VideoPresenFullmesh: 'aa900e9d-353a-41ee-b1b3-751a97104f4e',
      VideoPresenInAuditorium: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      AudioGame: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      AudioAuditorium: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      AudioGameInAuditorium: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      AudioGameInAuditoriumLarge: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      AudioPresen: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      AudioPresenInAuditorium: 'ae4c5882-2d66-472f-9d04-5859448eca41',
      AudioFullmesh: 'aa900e9d-353a-41ee-b1b3-751a97104f4e',
    },
    use_video_mobile: true,
    use_video_tablet: true,
  },
  agora: {
    AppID: 'f5b81d3d190448c0a2b0db98186384ed',
    logLevel: 4, // NONE
    // logLevel: 0, // DEBUG
  },
  // jisti_url: 'https://jisti-dev.mixidea.org',
  school: {
    shakaijin_school_id: 'kCBSB4yL1zdKUe1e9bbf',
    students_school_id: 'YgT3MckVnSieL0Cg1Ext',
    route_h_school_id: 'S3LQFM8WRpnJGFly0Yai',
    default_school_logo: 'https://storage.googleapis.com/mixidea_resource/icon_withname.png',
  },
  stripe: {
    public_key: 'pk_test_51HTefULJhlpUSQhvPm0GIcucsQ7TdCGrpVOYwVM2UilaSIkK5wdHiEim6AECNvkjwevNgO5eEkAdcsBwxdGIvRvG00uQHjjpov',
  },
  zoom_user_obj_arr: [{type:"A", mail:'yuta.moriyama@gmail.com'},{type:"B", mail:'yuta.moriyama@mixidea.org'}]
};
