import firebase from 'firebase/compat/app';
import {
  EventDataTimeDateConverted,
  ARTICLE_SHARED_RANGE,
  EventData,
  EVENT_APPLICATION_QUALIFICATION,
  EventDataVm,
  EventDataTimeDateConvertedWithId,
  EventDataVmWithId,
  EventDataWithId,
} from './event-data';
import { VIDEO_QUALITY } from '../constant/video';

import { EVENT_TYPE } from '../constant/event_type';

/////////
// school
/////////

export interface SchoolData {
  school_name: string;
  school_logo: string;
  description: string;
  school_admin_list: string[];
  school_teacher_list: string[];
  school_student_list: string[];
  school_all_members_list: string[];
  school_type: SCHOOL_TYPE;
  free_school?: boolean;
}

export enum SCHOOL_TYPE {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  TOURNAMENT = 'TOURNAMENT',
}

export interface SchoolApplicantInfo {
  first_name: string;
  last_name: string;
  email: string;
  email_2: string;
  email_3?: string;
  email_4?: string;
  telephone_number: string;
  occupation: string;
  working_detail: string;
  grade: SCHOOL_GRADE;
  school_name: string;
  motivation: string;
  debate_experience: DEBATE_EXPERIENCE;
  english_capabiilty: string;
  teaching_situation: TEACHING_SITUATION;
  added_timestamp?: firebase.firestore.Timestamp;
}

export enum DEBATE_EXPERIENCE {
  NO_EXPERIENCE = 'NO_EXPERIENCE',
  EXPERIENCE_IN_SCHOOL_CLASS = 'A_LITTLE_EXPERIENCE_IN_SCHOOL',
  EXPERIENCE_IN_DEBATE_CLUB = 'EXPERIENCE_IN_DEBATE_CLUB',
}

export const DEBATE_EXPERIENCE_DATA = {
  [DEBATE_EXPERIENCE.NO_EXPERIENCE]: '全く経験なし',
  [DEBATE_EXPERIENCE.EXPERIENCE_IN_SCHOOL_CLASS]: '学校の授業での経験あり',
  [DEBATE_EXPERIENCE.EXPERIENCE_IN_DEBATE_CLUB]: 'ディベートクラブでの経験あり',
};

export const DEBATE_EXPERIENCE_ARR = [
  DEBATE_EXPERIENCE.NO_EXPERIENCE,
  DEBATE_EXPERIENCE.EXPERIENCE_IN_SCHOOL_CLASS,
  DEBATE_EXPERIENCE.EXPERIENCE_IN_DEBATE_CLUB,
];

export enum TEACHING_SITUATION {
  NO_PLAN = 'NO_PLAN',
  PLANNING_IN_CLASS = 'PLANNING_IN_CLASS',
  ON_GOING_IN_CLASS = 'ON_GOING_IN_CLASS',
  PLANNING_IN_CLUB = 'PLANNING_IN_CLUB',
  ON_GOING_IN_CLUB = 'ON_GOING_IN_CLUB',
}

export const TEACHIMG_SITUATION_DATA = {
  [TEACHING_SITUATION.NO_PLAN]: '計画なし',
  [TEACHING_SITUATION.PLANNING_IN_CLASS]: '授業での実施を計画中',
  [TEACHING_SITUATION.ON_GOING_IN_CLASS]: '授業でディベートを実施中',
  [TEACHING_SITUATION.PLANNING_IN_CLUB]: 'クラブでの実施を計画中',
  [TEACHING_SITUATION.ON_GOING_IN_CLUB]: 'クラブでのディベートを実施中',
};

export const TEACHING_SITUATION_ARR = [
  TEACHING_SITUATION.NO_PLAN,
  TEACHING_SITUATION.PLANNING_IN_CLASS,
  TEACHING_SITUATION.ON_GOING_IN_CLASS,
  TEACHING_SITUATION.PLANNING_IN_CLUB,
  TEACHING_SITUATION.ON_GOING_IN_CLUB,
];

export enum SCHOOL_GRADE {
  ELEMENTARY_1 = 'ELEMENTARY_1',
  ELEMENTARY_2 = 'ELEMENTARY_2',
  ELEMENTARY_3 = 'ELEMENTARY_3',
  ELEMENTARY_4 = 'ELEMENTARY_4',
  ELEMENTARY_5 = 'ELEMENTARY_5',
  ELEMENTARY_6 = 'ELEMENTARY_6',
  JUNIOR_1 = 'JUNIOR_1',
  JUNIOR_2 = 'JUNIOR_2',
  JUNIOR_3 = 'JUNIOR_3',
  HIGH_1 = 'HIGH_1',
  HIGH_2 = 'HIGH_2',
  HIGH_3 = 'HIGH_3',
  UNIV_1 = 'UNIV_1',
  UNIV_2 = 'UNIV_2',
  UNIV_3 = 'UNIV_3',
  UNIV_4 = 'UNIV_4',
}

export const SCHOOL_GRADE_ARR = [
  SCHOOL_GRADE.ELEMENTARY_1,
  SCHOOL_GRADE.ELEMENTARY_2,
  SCHOOL_GRADE.ELEMENTARY_3,
  SCHOOL_GRADE.ELEMENTARY_4,
  SCHOOL_GRADE.ELEMENTARY_5,
  SCHOOL_GRADE.ELEMENTARY_6,
  SCHOOL_GRADE.JUNIOR_1,
  SCHOOL_GRADE.JUNIOR_2,
  SCHOOL_GRADE.JUNIOR_3,
  SCHOOL_GRADE.HIGH_1,
  SCHOOL_GRADE.HIGH_2,
  SCHOOL_GRADE.HIGH_3,
  SCHOOL_GRADE.UNIV_1,
  SCHOOL_GRADE.UNIV_2,
  SCHOOL_GRADE.UNIV_3,
  SCHOOL_GRADE.UNIV_4,
];

export const SCHOOL_GRADE_DATA = {
  [SCHOOL_GRADE.ELEMENTARY_1]: '小学校一年',
  [SCHOOL_GRADE.ELEMENTARY_2]: '小学校二年',
  [SCHOOL_GRADE.ELEMENTARY_3]: '小学校三年',
  [SCHOOL_GRADE.ELEMENTARY_4]: '小学校四年',
  [SCHOOL_GRADE.ELEMENTARY_5]: '小学校五年',
  [SCHOOL_GRADE.ELEMENTARY_6]: '小学校六年',
  [SCHOOL_GRADE.JUNIOR_1]: '中学一年',
  [SCHOOL_GRADE.JUNIOR_2]: '中学二年',
  [SCHOOL_GRADE.JUNIOR_3]: '中学三年',
  [SCHOOL_GRADE.HIGH_1]: '高校一年',
  [SCHOOL_GRADE.HIGH_2]: '高校二年',
  [SCHOOL_GRADE.HIGH_3]: '高校三年',
  [SCHOOL_GRADE.UNIV_1]: '大学一年',
  [SCHOOL_GRADE.UNIV_2]: '大学二年',
  [SCHOOL_GRADE.UNIV_3]: '大学三年',
  [SCHOOL_GRADE.UNIV_4]: '大学四年',
};

export enum OCCUPATION {
  SCHOOL_ENGLISH_TEACHER = 'SCHOOL_ENGLISH_TEACHER',
  SCHOOL_TEACHER = 'SCHOOL_TEACHER',
  CRAM_SCHOOL_TEACHER = 'CRAM_SCHOOL_TEACHER',
  BUSINESS_PERSON = 'BUSINESS_PERSON',
  STUDENTS = 'STUDENTS',
  OTHER = 'OTHER',
}
export const OCCUPATION_ARR = [
  OCCUPATION.SCHOOL_ENGLISH_TEACHER,
  OCCUPATION.SCHOOL_TEACHER,
  OCCUPATION.CRAM_SCHOOL_TEACHER,
  OCCUPATION.BUSINESS_PERSON,
  OCCUPATION.STUDENTS,
  OCCUPATION.OTHER,
];

export const OCCUPATION_DATA = {
  [OCCUPATION.SCHOOL_ENGLISH_TEACHER]: '学校英語教員',
  [OCCUPATION.SCHOOL_TEACHER]: '学校教員(英語以外)',
  [OCCUPATION.CRAM_SCHOOL_TEACHER]: '塾講師',
  [OCCUPATION.BUSINESS_PERSON]: '一般社会人',
  [OCCUPATION.STUDENTS]: '学生',
  [OCCUPATION.OTHER]: 'その他',
};

export interface SchoolDataWithId extends SchoolData {
  id: string;
}

//////////
// Lesson and Event With Lecture common part
//////////

export interface Lesson_LessonWithEvent_CommonPart {
  course_id?: string;
  course_name?: string;
  lesson_id?: string;
  lesson_group_title?: string;
  school_id?: string;
  school_name?: string;
  school_logo?: string;
  start_timestamp?: firebase.firestore.Timestamp;
}

//////////
// Event With Lecture
//////////

interface EventWithLectureAdditionaPartFromEvent extends Lesson_LessonWithEvent_CommonPart {
  all_lecture_related_users?: string[];
  teachers?: { [userid: string]: { teacher_type: TEACHER_TYPE } };
  users_by_student_action?: {
    [userid: string]: { student_action: EVENT_LESSON_ACTION_BY_STUDENTS; set_timestamp: firebase.firestore.Timestamp };
  };
  users_by_admin_action?: { [userid: string]: { admin_action: EVENT_LESSON_ACTION_BY_ADMIN; set_timestamp: firebase.firestore.Timestamp } };
  lesson_class_id?: string;
}

export interface EventDataWithLecture extends EventData, EventWithLectureAdditionaPartFromEvent {}
export interface EventDataWithLectureWithId extends EventDataWithId, EventWithLectureAdditionaPartFromEvent {}

export interface EventDataWithLectureTimeDateConverted extends EventDataTimeDateConverted, EventWithLectureAdditionaPartFromEvent {}
export interface EventDataWithLectureTimeDateConvertedWithId
  extends EventDataTimeDateConvertedWithId,
    EventWithLectureAdditionaPartFromEvent {}

export interface EventDataWithLectureVM extends EventDataVm, EventWithLectureAdditionaPartFromEvent {}
export interface EventDataWithLectureVMwithId extends EventDataVmWithId, EventWithLectureAdditionaPartFromEvent {}

export interface TeacherSubcollectionData {
  set_timestamp: firebase.firestore.Timestamp;
  set_by: string;
  teacher_type: TEACHER_TYPE;
}
export interface TeacherSubcollectionDataWithId extends TeacherSubcollectionData {
  id: string;
}

export const DEFAULT_EVENT_DATA_FOR_LECTURE: EventData = {
  duration: 2,
  type: EVENT_TYPE.DEBATE_LIVEVIDEO,
  article_shared_range: ARTICLE_SHARED_RANGE.PARTICIPANT,
  application_qualification: EVENT_APPLICATION_QUALIFICATION.SCHOOL_MEMBER,
  recording_activation: true,
  video_quality: VIDEO_QUALITY.HIGH,
};

// export interface EventLectureCreateParam {
//   event_lecture: EventDataWithLecture;
//   initial_invitation_users: string[];
// }

export enum TEACHER_TYPE {
  PAID_MAIN = 'PAID_MAIN',
  FREE_MAIN = 'FREE_MAIN',
  PAID_SUPPORTER = 'PAID_SUPPORTER',
  FREE_SUPPORTER = 'FREE_SUPPORTER',
  CANCEL = 'CANCEL',
}

/////////
/// Lesson
//////////

export interface LesssonSpecificData {
  event_id?: string;
  lesson_group_index?: number;
  lesson_status?: LESSON_STATUS;
  lesson_class_id?: string;

  attend_ok_users?: string[];
  attend_ng_users?: string[];
  attend_possible_users?: string[];
  attend_all_input_users?: string[];
}
export interface LessonData extends LesssonSpecificData, Lesson_LessonWithEvent_CommonPart {}

export interface LessonDataForBatchFunc extends LessonData {
  start_date?: Date;
  id?: string;
}

export interface LessonDataWithId extends LessonData {
  id?: string;
}

export interface LessonDataTimeDateConverted extends LessonData {
  start_date?: Date;
}

export interface LessonDataTimeDateConvertedWithId extends LessonDataTimeDateConverted {
  id?: string;
}

export enum LESSON_STATUS {
  UNDER_TEMPORAL_RESERVATION = 'UNDER_TEMPORAL_RESERVATION',
  TEMPORAL_RESERVATION_FINISHED = 'TEMPORAL_RESERVATION_FINISHED',
  LESSON_DISPOSED = 'LESSON_DISPOSED',
}

export interface LessonGroupSchedule {
  group_id?: number;
  lesson_group_title?: string;
  lesson_list?: LessonDataWithId[] | LessonDataWithIdWithMyIntension[];
}

export enum ParticipantIntension {
  OK = 'OK', //  ◯
  NG = 'NG', //  X
  POSSIBLE = 'POSSIBLE', //  △
}

export interface LessonDataWithIdWithMyIntension extends LessonDataWithId {
  my_intension: ParticipantIntension;
}

export interface LessonParticipantIntensionSubcollectionData {
  intension: ParticipantIntension;
  set_date: firebase.firestore.Timestamp;
  course_id: string;
}

//////////
// course
//////////

export interface CourseData {
  school_id?: string;
  teacher_ids?: string[];
  course_name?: string;
  content?: string;
  first_lesson_date?: Date;
  last_lesson_date?: Date;
  first_lesson_timestamp?: firebase.firestore.Timestamp;
  last_lesson_timestamp?: firebase.firestore.Timestamp;
  course_status?: COURSE_STATUS;
  course_price?: number;
  lesson_price?: number;
  min_people?: number;
  max_people?: number;
  reservation_note?: string;
  all_course_students?: string[];
  all_students_memo?: { [user_id: string]: string };
}

export interface CourseDataWithId extends CourseData {
  id: string;
}

export enum COURSE_STATUS {
  BEFORE_REGISTRATION = 'BEFORE_REGISTRATION',
  UNDER_TEMPORAL_RESERVATION = 'UNDER_TEMPORAL_RESERVATION',
  BOTH_TEMPORAL_RESERVATION_AND_EVENT_ONGOING = 'BOTH_TEMPORAL_RESERVATION_AND_EVENT_ONGOING',
  TEMPORAL_RESERVATION_FINISH_EVENT_ONGOING = 'TEMPORAL_RESERVATION_FINISH_EVENT_ONGOING',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL',
}

////////////////

export enum EVENT_LESSON_ACTION_BY_STUDENTS {
  GOING_CONFIRMATION = 'GOING_CONFIRMATION',
  REQUEST_GOING = 'REQUEST_GOING',
  CANCEL_AFTER_GOING_CONFIRMATION = 'CANCEL_AFTER_GOING_CONFIRMATION',
  REJECT_INVITATION = 'REJECT_INVITATION',
}

export enum EVENT_LESSON_ACTION_BY_ADMIN {
  INVITED = 'INVITED',
  INVITED_AGAIN_FROM_CANCEL_INVITATION = 'INVITED_AGAIN_FROM_CANCEL_INVITATION',
  INVITATION_CANCELLED_BY_ADMIN = 'INVITATION_CANCELLED_BY_ADMIN',
  STUDENTS_GOING_REQUEST_APPROVED = 'STUDENTS_GOING_REQUEST_APPROVED',
  STUDENTS_REQUEST_REJECTED = 'STUDENTS_REQUEST_REJECTED',
  STUDENTS_GOING_CONFIRMATION_REJECTED = 'STUDENTS_GOING_CONFIRMATION_REJECTED',
}

export enum EVENT_LESSON_PARTICIPATE_STATUS {
  INVITED = 'INVITED',
  INVITATION_REFUSED = 'INVITATION_REFUSED',
  REQUESTED = 'REQUESTED',
  GOING = 'GOING',
  CANCEL = 'CANCEL',
  REJECTED = 'REJECTED',
  NOT_SHOWN = 'NOT_SHOWN',
}

export const PARTICIPATE_WORD = {
  [EVENT_LESSON_PARTICIPATE_STATUS.INVITED]: '招待されています。',
  [EVENT_LESSON_PARTICIPATE_STATUS.INVITATION_REFUSED]: '招待を拒否しています',
  [EVENT_LESSON_PARTICIPATE_STATUS.GOING]: '参加確定',
  [EVENT_LESSON_PARTICIPATE_STATUS.REQUESTED]: '参加要求しています',
  [EVENT_LESSON_PARTICIPATE_STATUS.CANCEL]: 'キャンセル',
  [EVENT_LESSON_PARTICIPATE_STATUS.REJECTED]: '参加できなくなりました',
};

export function calculate_participate_status(
  student_action: EVENT_LESSON_ACTION_BY_STUDENTS,
  admin_action: EVENT_LESSON_ACTION_BY_ADMIN,
): EVENT_LESSON_PARTICIPATE_STATUS {
  switch (student_action) {
    case EVENT_LESSON_ACTION_BY_STUDENTS.GOING_CONFIRMATION:
      switch (admin_action) {
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED:
          return EVENT_LESSON_PARTICIPATE_STATUS.GOING;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED_AGAIN_FROM_CANCEL_INVITATION:
          return EVENT_LESSON_PARTICIPATE_STATUS.GOING;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITATION_CANCELLED_BY_ADMIN:
          return EVENT_LESSON_PARTICIPATE_STATUS.GOING;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_REQUEST_APPROVED:
          return EVENT_LESSON_PARTICIPATE_STATUS.GOING;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_REQUEST_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.REJECTED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_CONFIRMATION_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.REJECTED;
          break;
        default:
          return EVENT_LESSON_PARTICIPATE_STATUS.GOING;
          break;
      }

      break;
    case EVENT_LESSON_ACTION_BY_STUDENTS.REQUEST_GOING:
      switch (admin_action) {
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED:
          return EVENT_LESSON_PARTICIPATE_STATUS.GOING;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED_AGAIN_FROM_CANCEL_INVITATION:
          return EVENT_LESSON_PARTICIPATE_STATUS.GOING;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITATION_CANCELLED_BY_ADMIN:
          return EVENT_LESSON_PARTICIPATE_STATUS.REQUESTED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_REQUEST_APPROVED:
          return EVENT_LESSON_PARTICIPATE_STATUS.GOING;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_REQUEST_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.REJECTED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_CONFIRMATION_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.REJECTED;
          break;
        default:
          return EVENT_LESSON_PARTICIPATE_STATUS.REQUESTED;
          break;
      }
      break;
    case EVENT_LESSON_ACTION_BY_STUDENTS.CANCEL_AFTER_GOING_CONFIRMATION:
      switch (admin_action) {
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED:
          return EVENT_LESSON_PARTICIPATE_STATUS.CANCEL;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED_AGAIN_FROM_CANCEL_INVITATION:
          return EVENT_LESSON_PARTICIPATE_STATUS.CANCEL;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITATION_CANCELLED_BY_ADMIN:
          return EVENT_LESSON_PARTICIPATE_STATUS.CANCEL;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_REQUEST_APPROVED:
          return EVENT_LESSON_PARTICIPATE_STATUS.CANCEL;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_REQUEST_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.CANCEL;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_CONFIRMATION_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.CANCEL;
          break;
        default:
          return EVENT_LESSON_PARTICIPATE_STATUS.CANCEL;
          break;
      }
      break;
    case EVENT_LESSON_ACTION_BY_STUDENTS.REJECT_INVITATION:
      switch (admin_action) {
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITATION_REFUSED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED_AGAIN_FROM_CANCEL_INVITATION:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITATION_REFUSED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITATION_CANCELLED_BY_ADMIN:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITATION_REFUSED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_REQUEST_APPROVED:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITATION_REFUSED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_REQUEST_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITATION_REFUSED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_CONFIRMATION_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITATION_REFUSED;
          break;
        default:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITATION_REFUSED;
          break;
      }
      break;
    default:
      switch (admin_action) {
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITED_AGAIN_FROM_CANCEL_INVITATION:
          return EVENT_LESSON_PARTICIPATE_STATUS.INVITED;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.INVITATION_CANCELLED_BY_ADMIN:
          return EVENT_LESSON_PARTICIPATE_STATUS.NOT_SHOWN;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_REQUEST_APPROVED:
          return EVENT_LESSON_PARTICIPATE_STATUS.NOT_SHOWN;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_REQUEST_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.NOT_SHOWN;
          break;
        case EVENT_LESSON_ACTION_BY_ADMIN.STUDENTS_GOING_CONFIRMATION_REJECTED:
          return EVENT_LESSON_PARTICIPATE_STATUS.NOT_SHOWN;
          break;
        default:
          break;
      }
      break;
  }

  return EVENT_LESSON_PARTICIPATE_STATUS.NOT_SHOWN;
}
