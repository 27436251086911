import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

// type WhereFilterOp = '<' | '<=' | '==' | '>=' | '>';

export interface WhereParam {
  key: string;
  criteria: firebase.firestore.WhereFilterOp;
  value: string | number | Date | boolean | firebase.firestore.Timestamp;
}

export enum ORDERBY_DIRECTION {
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}

interface OrderParam {
  key: string;
  order: firebase.firestore.OrderByDirection;
}

export interface QueryParam {
  where_param: WhereParam[];
  orderby_param: OrderParam;
  startat_param: string;
  startafter_param: string;
  limit_param?: number;
}

const DefaultQueryParam: QueryParam = {
  where_param: [],
  orderby_param: null,
  startat_param: null,
  startafter_param: null,
};

@Injectable()
export class FirestoreService {
  constructor(private afs: AngularFirestore) {}

  getObjectValueOnce<T>(path): Observable<T> {
    const ref: AngularFirestoreDocument<T> = this.afs.doc(path);
    return ref.valueChanges().pipe(take(1));
  }

  getObjectValue<T>(path): Observable<T> {
    const ref: AngularFirestoreDocument<T> = this.afs.doc(path);
    return ref.valueChanges();
  }

  updateObject(path, data): Promise<void> {
    const updateObservable = this.afs.doc(path);
    return updateObservable.update(data);
  }

  async setObject(path, data): Promise<void> {
    const setObservable = this.afs.doc(path);
    return setObservable.set(data);
  }

  async setMergeObject(path, data): Promise<void> {
    const setObservable = this.afs.doc(path);
    return setObservable.set(data, { merge: true });
  }

  removeObject(path): Promise<void> {
    const removeObservable = this.afs.doc(path);
    return removeObservable.delete();
  }

  getSnapshotList<T>(path): Observable<T[]> {
    const ref: AngularFirestoreCollection<T> = this.afs.collection<T>(path);
    return ref.snapshotChanges().pipe(
      map((actions: any[]) => {
        return actions.map((a) => ({ id: a.payload.doc.id, ...a.payload.doc.data() }));
      }),
    );
  }

  async deleteAllDocumentsInSubcollection(subcollectionPath: string): Promise<void> {
    try {
      const subcollectionRef = this.afs.collection(subcollectionPath);
      const snapshot = await subcollectionRef.ref.get();
      const deletePromises = snapshot.docs.map((doc) => doc.ref.delete());
      await Promise.all(deletePromises);

      console.log(`サブコレクション '${subcollectionPath}' 内のすべてのドキュメントを削除しました`);
    } catch (error) {
      console.error(`サブコレクション '${subcollectionPath}' の削除中にエラーが発生しました:`, error);
      throw error;
    }
  }

  async deleteAllDocumentsInSubcollectionAndSubcollectionOfSubcollection(subcollectionPath: string, doeName: string): Promise<void> {
    try {
      const subcollectionRef = this.afs.collection(subcollectionPath);
      const snapshot = await subcollectionRef.ref.get();
      for (const doc of snapshot.docs) {
        const id = doc.id;
        const path = `${subcollectionPath}/${id}/${doeName}`;
        await this.deleteAllDocumentsInSubcollection(path);
        await doc.ref.delete();
      }
      console.log(`サブコレクション '${subcollectionPath}' およびその下層のサブコレクションを削除しました`);
    } catch (error) {
      console.error(`サブコレクション '${subcollectionPath}' およびその下層のサブコレクションの削除中にエラーが発生しました:`, error);
      throw error;
    }
  }

  getSnapshotListOnce<T>(path): Observable<T[]> {
    const ref: AngularFirestoreCollection<T> = this.afs.collection<T>(path);
    return ref.snapshotChanges().pipe(
      map((actions: any[]) => {
        return actions.map((a) => ({ id: a.payload.doc.id, ...a.payload.doc.data() }));
      }),
      take(1),
    );
  }

  getSnapshotListWithQuery<T>(path, query_param: QueryParam = DefaultQueryParam): Observable<T[]> {
    return this.afs
      .collection<T>(path, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

        query_param.where_param.forEach((where_param: WhereParam) => {
          if (where_param.value) {
            query = query.where(where_param.key, where_param.criteria, where_param.value);
          }
        });

        if (query_param.startafter_param) {
          query = query.startAfter(query_param.startafter_param);
        }
        if (query_param.startat_param) {
          query = query.startAt(query_param.startat_param);
        }
        if (query_param.orderby_param) {
          query = query.orderBy(query_param.orderby_param.key, query_param.orderby_param.order);
        }
        if (query_param.limit_param) {
          query = query.limit(query_param.limit_param);
        }
        return query;
      })
      .snapshotChanges()
      .pipe(
        map((actions: any[]) => {
          return actions.map((a) => ({ id: a.payload.doc.id, ...a.payload.doc.data() }));
        }),
      );
  }

  async pushList<T>(path, data): Promise<string> {
    const ref: AngularFirestoreCollection<T> = this.afs.collection<T>(path);
    return ref.add(data).then((resp_data: firebase.firestore.DocumentReference) => {
      return resp_data.id;
    });
  }
}
